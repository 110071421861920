import React, { useEffect, useState } from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import LayoutPage from "./pages/layout/LayoutPage";
import LayoutHomePage from "./pages/layout/LayoutHomePage";
import LayoutNewsList from "./pages/layout/LayoutNewsList";
import LayoutNewsDetail from "./pages/layout/LayoutNewsDetail";
import PageStatic from "./pages/pageStatic/PageStatic";
import { NewsCategoryEnum } from "./services/Api/DataInterface/NewsInterface";
import { GetListMenuResponseInterface } from "./services/Api/DataInterface/GetListMenuResponseInterface";
import { DynamicObjectSlugEnum } from "./services/Api/DynamicModuleService";
import DeansPage from "./pages/DeansPage";
import Layout404 from "./pages/layout/Layout404";
import PanelStaff from "./pages/PanelStaff";
import PublicCouncilOfFaculty from "./pages/PublicCouncilOfFaculty";
import GeneratePathApi from "./components/Menu/GeneratePathApi";
import container from "./container";
import LocaleEnum from "./services/Locale/enums/Locale/LocaleEnum";

const getNewsCategoryBySlug = (id: number): { slug: DynamicObjectSlugEnum; category?: NewsCategoryEnum } => {
    switch (id) {
        case 3:
            return {
                category: NewsCategoryEnum.News,
                slug: DynamicObjectSlugEnum.Announcement,
            };
        case 61:
            return {
                category: NewsCategoryEnum.NewsExtramural,
                slug: DynamicObjectSlugEnum.Announcement,
            };
        case 89:
        case 92:
            return {
                slug: DynamicObjectSlugEnum.DeanOfficeInformation,
            };
        case 93:
            return {
                slug: DynamicObjectSlugEnum.FromLifeOfTheFaculty,
            };
    }

    throw new Error(`Not valid slug ${id}`);
};

const getTimetableNewsSlugByPath = (id: number) => {
    switch(id) {
        case 70:
            return NewsCategoryEnum.TimetableSummer;
        case 71:
            return NewsCategoryEnum.TimetableWinter;
        case 72:
            return NewsCategoryEnum.TimetableExtramural;
    }

    throw new Error(`Not valid timetable id ${id}`);
};


interface PropsInterface {
    locale: LocaleEnum;
}

const getRoutesComponent = (id: number) => {
    switch (id) {
        case 1:
            return LayoutHomePage;
        case 91:
            return PanelStaff;
        case 89:
            return PublicCouncilOfFaculty;
        case 105:
            return DeansPage;
    }
    
    return null;
};

const Routes: React.FC<PropsInterface> = ({locale}) => {
    const [data, setData] = useState<GetListMenuResponseInterface[] | null>(null);

    useEffect(() => {
        (async () => {
            const routes:GetListMenuResponseInterface[] = [];
            const data = await container.menuService.getListMenu(locale);
            data.map((lvl1) => {
                routes.push(lvl1);

                if(lvl1.children?.length) {
                    lvl1.children.map((lvl2) => {
                        routes.push(lvl2);

                        if(lvl2.children?.length) {
                            lvl2.children.map((lvl3) => {
                                routes.push(lvl3);
                            });
                        }
                    });
                }
            });

            setData(routes);
        })();
    }, [locale]);

    if (!data) {
        return null;
    }
    
    return (
        <LayoutPage>
            <Switch>
                {data instanceof Array &&  data?.map((item: GetListMenuResponseInterface) => {
                    const path = GeneratePathApi(item);
                    const routeComponent = getRoutesComponent(item.id);

                    if([3, 61, 92, 93].indexOf(item.id) >= 0) {
                        return (
                            <Route exact path={GeneratePathApi(item, true)} key={item.id} 
                                component={(route: RouteComponentProps<{ newsSection: string; newsCategory: string; page?: string }>) => {
                                    const openItemId = route.location.hash && route.location.hash.match(/^#([0-9]+)$/) ? parseInt(route.location.hash.substr(1)) : undefined;
                                    const slugCategory = getNewsCategoryBySlug(item.id);
                                    return (
                                        <LayoutNewsList
                                            slug={slugCategory.slug}
                                            category={slugCategory.category}
                                            openItemId={openItemId}
                                            page={route.match.params.page ? parseInt(route.match.params.page) : 1}
                                        />
                                    );
                                }}
                            />
                        );
                    } else if([70, 71, 72].indexOf(item.id) >= 0) {
                        return (
                            <Route exact path={path} key={item.id}
                                component={(route: RouteComponentProps<{ slug: string }>) => {
                                    return (
                                        <LayoutNewsDetail
                                            dataSource={DynamicObjectSlugEnum.Timetable}
                                            slug={getTimetableNewsSlugByPath(item.id)}
                                        />
                                    );
                                }}
                            />
                        );
                    } else if(path.length && routeComponent) {
                        return <Route exact path={path} component={routeComponent} key={item.id} />;
                    } else if(path.length && item.pageId) {
                        return (
                            <Route exact path={path} key={item.id}>
                                <PageStatic pageId={item?.pageId} />
                            </Route>
                        );
                    } else if(path.length) {
                        return <Route exact path={path} key={item.id} />;
                    }

                })}
                <Route component={Layout404} />
            </Switch>
        </LayoutPage>
    );
};

export default Routes;
