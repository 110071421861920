import { createGlobalStyle } from "styled-components";

export const GlobalCss = createGlobalStyle`
    @font-face {
        font-family: 'Barlow';
        src: local('Barlow'), url(/fonts/Barlow-Regular.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Barlow-Bold';
        src: local('Barlow-Bold'), url(/fonts/Barlow-Bold.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Barlow-Medium';
        src: local('Barlow-Medium'), url(/fonts/Barlow-Medium.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Barlow-Light';
        src: local('Barlow-Light'), url(/fonts/Barlow-Light.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Barlow-SemiBold';
        src: local('Barlow-SemiBold'), url(/fonts/Barlow-SemiBold.ttf) format('truetype');
    }

    html, body, div, span, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, img,
    small, strong, ol, ul, li, fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td, article, aside,
    figure, figcaption, footer, header, menu, nav, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        box-sizing: border-box;
    }
    
    img {
        max-width: 100%;
        height: auto;
        filter: var(--imgage-grayscale-color, none)
    }

    body {
        background: var(--background-color, #FFF);
        color: var(--font-dark, #000);
        font-size: calc(16px + var(--font-modificator, 0px));
        font-family: 'Barlow', sans-serif;
        line-height: 1.3;
    }

    a {
        text-decoration: none;
        outline: none;
        color: var(--anchor-color, #000);

        &:hover {
            color: var(--font-dark, #000);
        }
    }

    .carousel.carousel-slider {
        overflow: visible;

        .slider-wrapper {
            overflow: visible;
        }

        .slide {
            opacity: 0;
            transition: opacity .3s;
            pointer-events: none;

            &.selected {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .slider--main {
        .carousel.carousel-slider {
            .slide {
                opacity: 1;
                z-index: auto !important;
            }
        }
    }

    .slider--gallery {
        overflow: hidden;

        .slider-wrapper.axis-horizontal .slider{
            
            .slide > div {
                flex-flow: row;
                display: flex;
                gap: 2px;
                flex-wrap: wrap;
            }
        }
    }

    :focus {
        outline: 3px solid rgba(0, 150, 255, 1);
        outline-offset: 3px;
    }

    .lg-sub-html {
        padding: 0.5vw 3.25vw;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(3px);
        overflow-y: auto;

        @media (max-width: 720px) {
            .desc {
                text-align: left;
            }
            
            .author {
                text-align: right;
            }
        }
    }
`;
